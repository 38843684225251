import { initializePaddle } from '@paddle/paddle-js';
import { toast } from 'react-toastify';

export const initializePaddleInstance = async () => {
  try {
    const paddleInstance = await initializePaddle({
      environment: 'sandbox',
      token: 'test_b5765f91cf7bd1f4120c7d80c27',
      eventCallback: function (data) {
        if (data.name === "checkout.completed") {
          toast.success("Checkout complete! Please wait a few moments and refresh the page to check updated credits.");
        }
    },

    });
    return paddleInstance;
  } catch (error) {
    console.error('Error initializing Paddle:', error);
    return null;
  }
};

export const openPaddleCheckout = (paddle, priceId,emailId) => {
  if (paddle) {
    paddle.Checkout.open({
      customer: {
                  email: emailId,
                  address: {
                    countryCode: "DE",
                    postalCode: "68161",
                  }
                },
      items: [{ priceId, quantity: 1 }],
    });
  } else {
    console.error('Paddle instance is not available.');
  }
};
