import React, { useEffect, useContext, useState } from 'react';
import { Card, Col, Row } from 'antd';
import logo from "../assets/logo2.png";
import { SideMenuContext } from '../context/sidemenu';
import { API, Auth } from "aws-amplify";
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import "./Dashboard.css";


function App(){
  const {setSelectedMenuItem } = useContext(SideMenuContext);
  const [computationData, setComputationData] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [isEmpty, setIsEmpty] = useState();
  const navigate = useNavigate();

  async function fetchData () {
    setIsLoading(true)
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const request = {
        headers: {
            Authorization: "Bearer " + token
        }
    };
    const jsonData= await API.get("diadem", "/calculation", request);
    const computationList= jsonData["body"]["resultListUnique"]

    if (jsonData.statusCode===200){
      const computationListSorted = computationList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setComputationData(computationListSorted);
      setIsEmpty(false)
      setIsLoading(false);
    }
    else{
      const body=JSON.parse(jsonData.body)
      if (body && body.exception){
        toast.error(body.exception);
        setIsEmpty(true)
        setIsLoading(false);
      }
    }

  }
  async function navigatePastCalculations (event) {
    navigate("/dashboard/computationAll");
  }
  // eslint-disable-next-line
  useEffect(() => {
    setSelectedMenuItem("dashboard"); // eslint-disable-line
    fetchData();
  }, []); // eslint-disable-line
  return (
    <>
            {isLoading ? (
                <div class="loading" id="loading-wrapper">
                <div id="loading-text">LOADING</div>
                <div id="loading-content"></div>
                </div>
            ) : (
                <>
                  <ToastContainer position="top-center" />
                    {isEmpty ? (

                        <div>
                            <h1>No past computations found!</h1>
                        </div>

                       ) :
                    (
                      <>
                        <div className='calculation-btn-container'>
                          <Button className="see-all-calculations" type="primary" onClick={navigatePastCalculations} >See all calculations</Button>
                        </div>

                        <Row gutter={[16, 16]} justify="space-evenly" align="middle">
                            {computationData.map((item, index) => (
                                <Col key={index}>
                                  <Link to={`/dashboard/${item.batchId}`}>
                                    <Card hoverable title={`Project ${index + 1}`} style={{ width: 300 }} bordered={false}
                                            cover={<img alt="example" src={logo} />}>

                                        {/* Customize the content inside the card based on your data */}
                                        <p><b>Batch Id : </b> {item.batchId}</p>
                                        <p><b>Submitted at : </b> {item.createdAt}</p>
                                        {/* Add more content as needed */}
                                      </Card>
                                    </Link>
                                  </Col>
                                ))}
                          </Row>
                        </>
                    )}
                        </>
                    )}
        </>

)};
export default App;
