import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import {Card} from 'antd';
import "./QueryResultBarChild.css"

const QueryResultBarChild = ({resultBody}) => {
    const data=[]
    const parsedResultBody=JSON.parse(resultBody)
    const propertyLenList=parsedResultBody["lengthList"]

    propertyLenList.map((item)=>{
    data.push({
        name: item["propertyName"],
        length: item["resultLength"],})
    return null;
    })

    let combinedLength = 0;
    let lenList = []
    parsedResultBody["lengthList"].forEach((item) => {
        if(item["propertyName"] === "Combined Results"){
            combinedLength = item["resultLength"];
        }
        else{
            lenList.push(item)
        }
    })
    return (
        <div className="result-bar-container">
            <Card title={`Your search returned ${combinedLength} results:`} bordered={false}>
                {lenList.map((item) =>
                    <p> within {item["propertyName"]} : {item["resultLength"]}</p>
                )}
                <p> <b>within all limits: {combinedLength} </b> </p>
            </Card>
            <BarChart width={400} height={250} data={data}>
                <XAxis dataKey="name" stroke="#27ddcc" />
                <YAxis />
                <Tooltip />
                <Legend width={100} wrapperStyle={{ top: 20, right: 20, backgroundColor: '#f5f5f5', border: '1px solid #d5d5d5', borderRadius: 3, lineHeight: '40px' }} />
                <CartesianGrid stroke="#27ddcc" strokeDasharray="5 5" />
                <Bar dataKey="length" fill="#27ddcc" barSize={30} />
            </BarChart>
        </div>

      );


}

export default QueryResultBarChild
