import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../lib/contextLib";
import { useFormFields } from "../lib/hooksLib";
import { onError } from "../lib/errorLib";
import "./Login.css";
import logo from "../assets/logo2.png";
import fbicon from "../assets/fb_icon.png";
import twittericon from "../assets/twitter_icon.png";
import googleicon from "../assets/google_icon.png";
import { Link } from "react-router-dom";


export default function Login() {
  const nav = useNavigate();
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
  });

  useEffect(() => {
    const rememberMeValue = localStorage.getItem('rememberMe');
    setRememberMe(rememberMeValue === 'true');
  }, []);

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };
  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    if (rememberMe) {
      localStorage.setItem('rememberMe', 'true');
    } else {
      localStorage.removeItem('rememberMe');
    }

    try {
      await Auth.signIn(fields.email, fields.password);
      userHasAuthenticated(true);
      nav("/dashboard");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }


  return (
    <div className="outer">
      <div className="logo-bar">
          <div className="logo-image">
            <img src={logo} alt="logo" className="logo-image"></img>
          </div>
      </div>
      <div className="label-bar">
          <div className="label-heading">Sign in to your account
          </div>
      </div>
      <div className="icon-bar">
        <div className="facebook-icon social-media-icon">
        <img src={fbicon} alt="fbicon" className="fb-icon social-media"></img>
        </div>
        <div className="twitter-icon social-media-icon">
        <img src={twittericon} alt="twittericon" className="twitter-icon social-media"></img>
        </div>
        <div className="google-icon social-media-icon">
        <img src={googleicon} alt="googleicon" className="google-icon social-media"></img>
        </div>
      </div>
      <div className="middle-container">
        <div className="line"></div>
        <div className="text-line"> Or continue with </div>
        <div className="line"></div>
      </div>


    <div className="Login">
      <Form onSubmit={handleSubmit}>
        <Form.Group size="lg" controlId="email">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <div className="links-container">
          <Form.Group controlId="rememberMe">
            <Form.Check
              type="checkbox"
              label="Remember Me"
              checked={rememberMe}
              onChange={handleRememberMeChange}
            />
            </Form.Group>
            <div className="links">
            <Link to="/signup" className="sub-link">Create an account</Link>
            <Link to="#about" className="sub-link">Forgot your password?</Link>
            </div>
        </div>
        <LoaderButton
          block="true"
          size="lg"
          type="submit"
          isLoading={isLoading}
          disabled={!validateForm()}
          className="login-button"
        >
          Login
        </LoaderButton>
      </Form>
    </div>
    </div>
  );
}
