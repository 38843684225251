import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./containers/Home";
import Login from "./containers/Login";
import NotFound from "./containers/NotFound";
import Signup from "./containers/Signup";
import Dashboard from "./containers/Dashboard";
import CalculationResultHistory from "./containers/CalculationResultHistory";
import ComputationAll from "./containers/ComputationAll";
import QueryChild from "./containers/QueryChild";
import QueryParent from "./containers/QueryParent";
import QueryResult from "./containers/QueryResult";
import QueryHistory from "./containers/QueryHistory";
import MoleculeView from "./containers/MoleculeView";
import Cart from './components/Cart';
import Profile from "./containers/Profile";
import CalculationSetUp from "./containers/CalculationSetUp";
import CalculationResult from "./containers/CalculationResult";
import PastQueryResult from "./containers/PastQueryResult";

export default function Links() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<NotFound />} />;
      <Route path="/signup" element={<Signup />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/dashboard/:batchId" element={<CalculationResultHistory />} />
      <Route path="/dashboard/computationAll" element={<ComputationAll />} />
      <Route path="/queryChild" element={<QueryChild />} />
      <Route path="/queryParent" element={<QueryParent />} />
      <Route path="/queryResult" element={<QueryResult />} />
      <Route path="/moleculeView/:molId" element={<MoleculeView />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/calculationSetUp" element={<CalculationSetUp />} />
      <Route path="/calculationResult" element={<CalculationResult />} />
      <Route path="/queryHistory" element={<QueryHistory />} />
      <Route path="/queryHistory/:queryId" element={<PastQueryResult />} />
    </Routes>
  );
}
