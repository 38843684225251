import React, { useEffect ,useState, useContext} from 'react';
import { Card } from 'antd';
import { Button, Form, Input } from 'antd';
import "./Profile.css";
import { SideMenuContext } from '../context/sidemenu';
import { initializePaddleInstance, openPaddleCheckout } from "../components/PaddleLoader";
import { API, Auth } from "aws-amplify";
import { ToastContainer } from 'react-toastify';

export default function Profile(){
    const [emailId, setEmailId]=useState("");
    const {setSelectedMenuItem } = useContext(SideMenuContext);
    const [paddle, setPaddle] = useState();
    const [credits, setCredits] = useState(null);

    async function tokenLoader() {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        const emailId=user.username
        return [token,emailId];
    };

    async function userData() {
        const [, emailId]= await tokenLoader();
        setEmailId(emailId)
    };


    async function fetchCredits() {
        const [token,]= await tokenLoader();
        try {
            const request = {
                headers: {
                    Authorization: "Bearer " + token
                }
            };
            const jsonDataUser= await API.get("diadem",'/user/userInfo', request);
            setCredits(jsonDataUser["body"]["credits"]);
        } catch (error) {
          console.error('Error fetching credits:', error);
        }
      };
    useEffect(()=> {userData()
        setSelectedMenuItem("profile")
        initializePaddleInstance().then((paddleInstance) => {
            setPaddle(paddleInstance);
            });
        fetchCredits();
        });

    const onFinish = (values) => {
      };
    const onFinishFailed = (errorInfo) => {
      };

    return (
        <>
        <ToastContainer position="top-center" />
        <div className="Profile">
            <h1>Profile</h1>

            <div className="container-up">

                    <Card
                        className="account-card"
                        title="Account Information"
                        bordered={false}
                        style={{
                        width: 300,
                        }}
                    >
                        <Form>
                        <p>User email : {emailId}</p>
                        <p>Subcription : Basic Subscription</p>

                        {credits !== null ? (
                        <p>Current Credits: {credits}</p>
                        ) : (
                            <p>Loading credits...</p>
                            )}

                        <Form.Item
                            wrapperCol={{
                                offset: 4,
                                span: 16,
                            }}
                            >
                            <Button type="primary" onClick={() => openPaddleCheckout(paddle, 'pri_01hkm8rz010346rpt0xetsecnb',emailId)}>
                            Buy Credits
                            </Button>
                            </Form.Item>
                        </Form>

                    </Card>
                    <Card
                        className="personal-card"
                        title="Personal Information"
                        >
                        <Form
                            className="personal-info"
                            name="basic"
                            labelCol={{
                            span: 8,
                            }}
                            wrapperCol={{
                            span: 16,
                            }}
                            style={{
                            maxWidth: 600,
                            }}
                            initialValues={{
                            remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                            label=" First Name"
                            name="firstName"
                            rules={[
                                {
                                required: true,
                                message: 'Please input your first name!',
                                },
                            ]}
                            >
                            <Input />
                            </Form.Item>
                            <Form.Item
                            label=" Last Name"
                            name="lastName"
                            rules={[
                                {
                                required: true,
                                message: 'Please input your last name!',
                                },
                            ]}
                            >
                            <Input />
                            </Form.Item>

                            <Form.Item
                            label="Contact Number"
                            name="number"
                            rules={[
                                {
                                required: false,
                                message: 'Please input your contact number!',
                                },
                            ]}
                            >
                            <Input />

                            </Form.Item>


                            <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                            >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                            </Form.Item>
                        </Form>
                    </Card>

            </div>
            <Card
                className="billing-card"
                title="Shipping Information"
            >
            <Form

                name="basic"
                labelCol={{
                span: 8,
                }}
                wrapperCol={{
                span: 16,
                }}
                style={{
                maxWidth: 600,
                }}
                initialValues={{
                remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                label="Address"
                name="address"
                rules={[
                    {
                    required: false,
                    message: 'Please input your address!',
                    },
                ]}
                >
                <Input.TextArea rows={4} />
                </Form.Item>

                <Form.Item
                label="Country/Region"
                name="region"
                rules={[
                    {
                    required: false,
                    message: 'Please input your country!',
                    },
                ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                label="Postal code"
                name="postalCode"
                rules={[
                    {
                    required: false,
                    message: 'Please input your postal code!',
                    },
                ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                label="Phone number"
                name="number"
                rules={[
                    {
                    required: false,
                    message: 'Please input your phone number!',
                    },
                ]}
                >
                <Input />
                </Form.Item>

                <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
                >
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                </Form.Item>
            </Form>
            </Card>
        </div>
        </>
      );
}
