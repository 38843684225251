import { useContext } from 'react';
import { CartContext } from '../context/cart';
import { Table } from "ant-table-extensions";
import "./Cart.css"
import {
  PlusOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import {Button} from 'antd';
import { Card } from 'antd';
import { Form, Input } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import { API, Auth } from "aws-amplify";

export default function Cart() {
    const { cartItems, addToCart, removeFromCart, clearCart, getCartTotal, getEachItemTotal } = useContext(CartContext)

    const onFinish = async (values) => {
      try{
        const { firstName, lastName, address, region, number } = values;
        const contentDict = {
          firstName,
          lastName,
          ...(address && { address }),
          ...(region && { region }),
          ...(number && { number }),
          cartItems
        };
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;

        const request = {
            headers: {
                Authorization: "Bearer " + token
            },
            body:{
              contentDict
            }
        };

        const jsonResponse= await API.put("diadem", "/sendEmail", request);

        toast.success(jsonResponse["body"]["message"])

    } catch (error) {
      // Handle error
      toast.error('Error requesting quote:', error);
    }
    };

    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };
    const columns = [
      {
        title: 'InchiKey',
        dataIndex: 'inchiKey',
      },
      {
        title: 'Source',
        dataIndex: 'source',
      },
      {
        title: 'Quantity( 10mg )',
        dataIndex: 'quantity',
      },
      {
        title: 'Indictive Price',
        dataIndex: 'price',
      },
    ];

  const data = [];
  const resultLength=cartItems.length
  for (let i = 0; i < resultLength; i++) {
    data.push({
      key:i,
      inchiKey: cartItems[i].inchiKey,
      quantity: <div className="quantity-control" >
                    <button
                      onClick={() => {
                        addToCart(cartItems[i])
                      }}
                    >
                    <PlusOutlined />
                    </button>
                    <p>{cartItems[i].quantity}</p>
                    <button
                      onClick={() => {
                        removeFromCart(cartItems[i])
                      }}
                    >
                      <MinusOutlined />
                    </button>
                </div>,
      price: "$"+getEachItemTotal(cartItems[i]),
    });
  }
  if( cartItems.length > 0 ){
    data[resultLength]={
      quantity: <h4>Total Price</h4>,
      price: "$"+getCartTotal(),
    };
  }


    return (
      <>
      <ToastContainer position="top-center" />
      <div >
        <h3 >Cart</h3>
        {
            cartItems.length > 0 ? (
              <div>
                <Card className='cart-card'>
               <Table columns={columns} dataSource={data} />

            <Button
              onClick={() => {
                clearCart()
              }}
            >
              Clear cart
            </Button>
            </Card>
          </div>
            ) : (
              <h4>Your cart is empty</h4>
            )
        }
      </div>
      <div>
      <Card
          className="billingcart-card"
            >
            <Form

                name="basic"
                labelCol={{
                span: 8,
                }}
                wrapperCol={{
                span: 16,
                }}
                style={{
                maxWidth: 600,
                }}
                initialValues={{
                remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
               <Form.Item
                  label=" First Name"
                  name="firstName"
                  rules={[
                      {
                      required: true,
                      message: 'Please input your first name!',
                      },
                  ]}
                  >
                  <Input />
                </Form.Item>
                <Form.Item
                  label=" Last Name"
                  name="lastName"
                  rules={[
                      {
                      required: true,
                      message: 'Please input your last name!',
                      },
                  ]}
                  >
                  <Input />
                </Form.Item>

                <Form.Item
                label="Phone number"
                name="number"
                rules={[
                    {
                    required: false,
                    message: 'Please input your phone number!',
                    }
                ]}
                >
                <Input />
                </Form.Item>

                <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
                >
                <Button type="primary" htmlType="submit">
                    Request Quote
                </Button>
                </Form.Item>
            </Form>
            </Card>
      </div>
            </>
          )
  }
