import React, { useState, useContext, useEffect } from 'react';
import { API, Auth } from "aws-amplify";

import { Table } from "ant-table-extensions";
import "./QueryResult.css"
import { SideMenuContext } from '../context/sidemenu';
import { ToastContainer , toast} from 'react-toastify';
import { Link} from "react-router-dom";

function QueryHistory(){
    const {setSelectedMenuItem } = useContext(SideMenuContext)
    const [dataQuery, setDataQuery] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const columns = [
        {
            title: 'Serial no',
            dataIndex: 'index',
        },
        {
            title: 'Query',
            dataIndex: 'query',
            render: (query,row) => <Link to={`/queryHistory/${row.id}`} >{query}</Link>
        },
        {
            title: 'Searched at',
            dataIndex: 'searchedAt',
            defaultSortOrder: 'descend',
            sorter: (a, b) => new Date(b.searchedAt) - new Date(a.searchedAt)
        }
      ];

    async function handlePastSearches () {
        setIsLoading(true);
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        const request = {
            headers: {
                Authorization: "Bearer " + token
            }
        };
        const jsonDataQuery= await API.get("diadem", "/search/past-searches", request);
        if (jsonDataQuery.statusCode===200){
            setIsLoading(false);
            setDataQuery(jsonDataQuery["body"]);
            // navigate("/queryResult",{state:{data:jsonDataPCT}});
          }
        else{
            const body=JSON.parse(jsonDataQuery.body)
            if (body && body.exception){
                toast.error(body.exception);
                setIsLoading(false);
            }
            }
        }
     // eslint-disable-next-line
     useEffect(() => {
        setSelectedMenuItem("search"); // eslint-disable-line
        handlePastSearches(); // eslint-disable-line
    }, []); // eslint-disable-line

    const searchesData = [];
    const notEmpty=(Object.keys(dataQuery).length !== 0)
    const resultLength=dataQuery.length
    if (notEmpty){
        for (let i = 0; i < resultLength; i++) {
            searchesData.push({
            key:i,
            index: i+1,
            id: dataQuery[i]["id"],
            query: dataQuery[i]["query"],
            searchedAt: dataQuery[i]["createdAt"],
            });
        }
    }

    return(
        <>
            {isLoading ? (
                <div class="loading" id="loading-wrapper">
                <div id="loading-text">LOADING</div>
                <div id="loading-content"></div>
                </div>
            ) : (
                <>
                  <ToastContainer position="top-center" />
                    {notEmpty ? (
                        <div>
                            <h1>Past searches of the user </h1>
                            <Table columns={columns} dataSource={searchesData} showSorterTooltip={{ target: 'sorter-icon' }}/>

                        </div> ) :
                    (
                        <div>
                            <h1>No past searches found!</h1>
                        </div>
                    )}
                        </>
                    )}
        </>
    );
}

export default QueryHistory;
