import React, { useState, useEffect, useContext } from "react";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../lib/contextLib";
import { useFormFields } from "../lib/hooksLib";
import { onError } from "../lib/errorLib";
import "./Signup.css";
import logo from "../assets/logo2.png";
import { Link } from "react-router-dom";
import { SideMenuContext } from '../context/sidemenu';
import { API, Auth } from "aws-amplify";

export default function Signup() {
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
  });
  const { setSelectedMenuItem } = useContext(SideMenuContext);
  // eslint-disable-next-line
  useEffect(() => {
    setSelectedMenuItem("dashboard"); // eslint-disable-line
  }, []); // eslint-disable-line
  const nav = useNavigate();
  const [newUser, setNewUser] = useState(null);
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] = useState(false);
  const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState(false);
  const checkTermsAndConditionsChecked = () => {
    setIsTermsAndConditionsChecked(!isTermsAndConditionsChecked)
  }
  const checkPrivacyPolicyChecked = () => {
    setIsPrivacyPolicyChecked(!isPrivacyPolicyChecked)
  }
  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword &&
      isTermsAndConditionsChecked &&
      isPrivacyPolicyChecked
    );

  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      const newUser = await Auth.signUp({
        username: fields.email,
        password: fields.password,

      });
      setIsLoading(false);
      setNewUser(newUser);
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  async function processCheckout() {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const request = {
      headers: {
        Authorization: "Bearer " + token,
      }
    };

    try {
      await API.get("diadem", "/user", request);
    } catch (error) {
      console.error("Error adding user :", error);
    }
  }

  async function handleConfirmationSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      const userConfirmation=await Auth.confirmSignUp(fields.email, fields.confirmationCode);
      await Auth.signIn(fields.email, fields.password);
      userHasAuthenticated(true);
      if (userConfirmation==="SUCCESS"){
        processCheckout();
       }
      nav("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function renderConfirmationForm() {
    return (
      <Form onSubmit={handleConfirmationSubmit}>
        <Form.Group controlId="confirmationCode" size="lg">
          <Form.Label>Confirmation Code</Form.Label>
          <Form.Control
            autoFocus
            type="tel"
            onChange={handleFieldChange}
            value={fields.confirmationCode}
          />
          <Form.Text muted>Please check your email for the code.</Form.Text>
        </Form.Group>
        <LoaderButton
          block="true"
          size="lg"
          type="submit"
          variant="success"
          isLoading={isLoading}
          disabled={!validateConfirmationForm()}
        >
          Verify
        </LoaderButton>
      </Form>
    );
  }
  function renderForm() {
    return (
      <div className="outer">
        <div className="logo-bar">
          <div className="logo-image">
            <img src={logo} alt="logo" className="logo-image"></img>
          </div>
        </div>
        <div className="label-bar">
          <div className="label-heading">Create an account
          </div>
        </div>
        <div className="Signup">
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="email" size="lg">
              <div className="email-bar">
                <Form.Label>Email</Form.Label>
                  <div className="links">
                    <Link to="/login" className="sub-link">Login to existing account</Link>
                  </div>
              </div>
              <Form.Control
                autoFocus
                type="email"
                value={fields.email}
                onChange={handleFieldChange}
              />
            </Form.Group>
            <Form.Group controlId="password" size="lg">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={fields.password}
                onChange={handleFieldChange}
              />
            </Form.Group>
            <Form.Group controlId="confirmPassword" size="lg">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                onChange={handleFieldChange}
                value={fields.confirmPassword}
              />
            </Form.Group>
            <div>
              <input
                type="checkbox"
                id="checkbox1"
                checked={isTermsAndConditionsChecked}
                onChange={checkTermsAndConditionsChecked}
              />
              <label htmlFor="checkbox" className="checkbox" id="agreeCheckbox"> By signing up you agree to the <a href="https://diadem.readthedocs.io/en/latest/legal/TAC.html" className="sub-link" >terms and conditions</a> </label>
              {/* <p>The checkbox is {isTermsAndConditionsChecked ? "checked" : "unchecked"}</p> */}
            </div>

            <div>
              <input
                type="checkbox"
                id="checkbox2"
                checked={isPrivacyPolicyChecked}
                onChange={checkPrivacyPolicyChecked}
              />
              <label htmlFor="checkbox" className="checkbox" > By signing up you agree to the <a href="https://diadem.readthedocs.io/en/latest/legal/PrivacyPolicy.html" className="sub-link">privacy and data policy </a></label>
            </div>
            <LoaderButton
              block="true"
              size="lg"
              type="submit"
              variant="success"
              isLoading={isLoading}
              disabled={!validateForm()}
              className="signup-button"
            >
              Signup
            </LoaderButton>

          </Form>
          </div>
      </div>

    );
  }

  return (
    <div className="Signup">
      {newUser === null ? renderForm() : renderConfirmationForm()}
    </div>
  );
}
