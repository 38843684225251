import React, { useState, useContext } from 'react';
import { Button, Space} from 'antd';
import { Table } from "ant-table-extensions";
import { Link} from "react-router-dom";
import "./QueryResultChild.css"
import { onDemandMoleculesContext } from '../context/onDemandMolecules'
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { CartContext } from '../context/cart';
import { toast } from 'react-toastify';

const QueryResultChild = ({resultBody}) => {
    const { addMultipleToOnDemandMoleculesList } = useContext(onDemandMoleculesContext)
    const nav = useNavigate();
    const { addToCartMultiple } = useContext(CartContext);
    const columns = [
      {
        title: 'Inchikey',
        dataIndex: 'molId',
        render: (molId) => <Link to={`/moleculeView/${molId}`} >{molId}</Link>
      },
        {
          title: 'Smiles',
          dataIndex: 'smiles',
          render: (smiles,row) => <Link to={`/moleculeView/${row.molId}`} >{smiles}</Link>
        },
        {
          title: 'Property Name',
          dataIndex: 'propertyName',
        },
        {
          title: 'Value',
          dataIndex: 'value',
        },
        {
          title: 'Calculator',
          dataIndex: 'calculatorName',
        },
        {
          title: 'calculator Version',
          dataIndex: 'version',
        },
      ];
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const parsedResultBody=JSON.parse(resultBody)
  const start = () => {
    setLoading(true);
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };
  const onDemand = (event) => {
    let selctedMolecules=[]
    selectedRowKeys.map((itemKey) => {
      selctedMolecules.push(data[itemKey])
      return null;
    }
    )
    addMultipleToOnDemandMoleculesList(selctedMolecules);

    setLoading(true);
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
    nav("/calculationSetUp")
  };
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const colPropertyList=parsedResultBody["result"]
  const resultLength=colPropertyList.length
  const csvData = selectedRowKeys.map(i => ({
      inchiKey: colPropertyList[i]["molId"],
      smiles: colPropertyList[i]["smiles"],
      propertyName: colPropertyList[i]["propertyName"],
      value: colPropertyList[i]["value"],
      unit: colPropertyList[i]["unit"],
      calculatorName: colPropertyList[i]["calculatorId"],
      version: colPropertyList[i]["version"],
  }));
  const hasSelected = selectedRowKeys.length > 0;
  const data = [];
  const notEmpty=(Object.keys(colPropertyList).length !== 0)

  if (notEmpty){
    for (let i = 0; i < resultLength; i++) {
      data.push({
        key:i,
        molId: colPropertyList[i]["molId"],
        smiles: colPropertyList[i]["smiles"],
        propertyName: colPropertyList[i]["propertyName"],
        value:`${ Number(colPropertyList[i]["value"]).toFixed(2)} ${colPropertyList[i]["unit"]}`,
        calculatorName: colPropertyList[i]["calculatorId"],
        version: colPropertyList[i]["version"],
      });
    }
  }

  const addMoleculesToCart = (event) => {
    addToCartMultiple(csvData);
    toast.success("Molecules added to cart successfully!");
  };
  return (
    <div>
      <div className="result-table-btn-container">
        <Space wrap>
          <Button type="primary" onClick={onDemand} disabled={!hasSelected} loading={loading}>
            Run on demand calculation
          </Button>
          <Button type="primary" loading={loading} onClick={addMoleculesToCart}>Add to cart</Button>
          <Button type="primary" onClick={start} disabled={!hasSelected} loading={loading}>
            Compare molecules
          </Button>
          <Button type="primary" loading={loading}>
          <CSVLink className="downloadbtn" filename="my-file.csv" data={csvData}>
          Export to CSV
        </CSVLink>
        </Button>
        </Space>
        <span className="selected-text" >
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
        </span>
      </div>
      <Table rowSelection={rowSelection} columns={columns} dataSource={data} scroll={{ x: 400 }} searchable/>
    </div>
  );
};
export default QueryResultChild;
