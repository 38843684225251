import { AntDValueEditor } from '@react-querybuilder/antd';
import { ValueEditor} from 'react-querybuilder';
import {Select, Space} from 'antd';

const CustomValueEditor = (props) => {
    const {value, handleOnChange} = props;
    if(props.field === "calculator"){
      const [val1, val2] = value === "" ? ["", ""] : value;

      let opts = [];

      props.values.forEach((item) => {
        if (item.name === val1) {
          opts = item.versions;
        }
      });

    return (
        <Space wrap>
            <Select
                value={val1}
                onChange={(e) => handleOnChange([e, val2])}
                options={props.values.map((calc) => ({ label: calc.name, value: calc.name }))}
            />
            having version
            <Select
                value={val2}
                onChange={(e) => handleOnChange([val1, e])}
                options={opts.map((version) => ({ label: version.name, value: version.name }))}
            />
        </Space>
      );

    }
    if(props.field === "value"){
      // return <InputNumber onChange={handleOnChange} {...props}/>;
      return <ValueEditor {...props}/>;
    }
    return <AntDValueEditor {...props}/>;

  }
export default CustomValueEditor;
