import { createContext, useState} from 'react'

export const SideMenuContext = createContext()

export const SideMenuProvider = ({ children }) => {
      const [selectedMenuItem, setSelectedMenuItem] = useState('dashboard')

      return (
        <SideMenuContext.Provider
          value={{
            selectedMenuItem,
            setSelectedMenuItem,
          }}
        >
          {children}
        </SideMenuContext.Provider>
      );
    };
