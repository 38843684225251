import { createContext, useState, useEffect } from 'react'

export const CartContext = createContext()

export const CartProvider = ({ children }) => {
// TODO :: After M18,instead of localStorage, use database since localStorage is less secure
  const [cartItems, setCartItems] = useState(localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [])
  const price_10mg = 128.35
  const addToCart = (item) => {
    const isItemInCart = cartItems.find((cartItem) => cartItem.inchiKey === item.inchiKey);
    if (isItemInCart) {
      setCartItems(
        cartItems.map((cartItem) =>
          cartItem.inchiKey === item.inchiKey
            ? { ...cartItem, quantity: cartItem.quantity + 1 }
            : cartItem
        )
      );
    } else {
      setCartItems([...cartItems, { ...item, quantity: 1 }]);
    }
  };
  const addToCartMultiple = (items) => {
    const updatedCartItems = [...cartItems];
    items.forEach((item) => {
      const isItemInCart = updatedCartItems.find(
        (cartItem) => cartItem.inchiKey === item.inchiKey
      );

      if (isItemInCart){
        updatedCartItems.forEach((cartItem, index) => {
          if (cartItem.inchiKey === item.inchiKey) {
            updatedCartItems[index] = { ...cartItem, quantity: cartItem.quantity + 1 };
          }
        });
      }
     else {
        updatedCartItems.push({ ...item, quantity: 1 });
      }
    });
    setCartItems(updatedCartItems);
  };

  const removeFromCart = (item) => {
    const isItemInCart = cartItems.find((cartItem) => cartItem.inchiKey === item.inchiKey);

    if (isItemInCart.quantity === 1) {
      setCartItems(cartItems.filter((cartItem) => cartItem.inchiKey !== item.inchiKey));
    } else {
      setCartItems(
        cartItems.map((cartItem) =>
          cartItem.inchiKey === item.inchiKey
            ? { ...cartItem, quantity: cartItem.quantity - 1 }
            : cartItem
        )
      );
    }
  };

  const clearCart = () => {
    setCartItems([]);
  };

  const getCartTotal = () => {
    const totalPrice = cartItems.reduce((total, item) => total + price_10mg * item.quantity, 0).toFixed(2);
    return totalPrice;
  };

  const getEachItemTotal = (item) => {
    // TODO :: add price of molecule in DB , for now ,hardcoding
    // return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
    //  hardcoding price of 10mg

    return (price_10mg * item.quantity).toFixed(2);
  };

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  useEffect(() => {
    const cartItems = localStorage.getItem("cartItems");
    if (cartItems) {
      setCartItems(JSON.parse(cartItems));
    }
  }, []);

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        clearCart,
        getCartTotal,
        getEachItemTotal,
        addToCartMultiple,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
