import { createContext, useState, useEffect } from 'react'

export const onDemandMoleculesContext = createContext()

export const OnDemandMoleculesProvider = ({ children }) => {
// TODO :: After M18,instead of localStorage, use database since localStorage is less secure
  const [onDemandMolecules, setOnDemandMolecules] = useState(localStorage.getItem('onDemandMolecules') ? JSON.parse(localStorage.getItem('onDemandMolecules')) : [])

  const addSingleToOnDemandMoleculesList = (item) => {
    const isMoleculePresent = onDemandMolecules.find((onDemandMolecule) => onDemandMolecule.molId === item.molId);
    if (isMoleculePresent) {
        alert("Molecule already present for calculation")
    } else {
      setOnDemandMolecules([...onDemandMolecules, { ...item }]);
    }
  };

  const addMultipleToOnDemandMoleculesList = (itemList) => {
    let newMolList = [];
    let alreadyExistingMol = []
    itemList.map((item)=>{
      const isMoleculePresent = onDemandMolecules.find((onDemandMolecule) => onDemandMolecule.molId === item.molId);
      if (isMoleculePresent) {
        alreadyExistingMol.push(item);
      } else {
        newMolList.push(item);
      }
      return null;
    })

    if (alreadyExistingMol.length > 0){
      alert(`${alreadyExistingMol.length} molecules already exist, so not added to on demand calculation`)
    }

    setOnDemandMolecules([...onDemandMolecules, ...newMolList ]);
  };


  const clearOnDemandMoleculesList = () => {
    setOnDemandMolecules([]);
  };

  useEffect(() => {
    localStorage.setItem("onDemandMolecules", JSON.stringify(onDemandMolecules));
  }, [onDemandMolecules]);

  useEffect(() => {
    const onDemandMolecules = localStorage.getItem("onDemandMolecules");
    if (onDemandMolecules) {
      setOnDemandMolecules(JSON.parse(onDemandMolecules));
    }
  }, []);

  return (
    <onDemandMoleculesContext.Provider
      value={{
        onDemandMolecules,
        addSingleToOnDemandMoleculesList,
        addMultipleToOnDemandMoleculesList,
        clearOnDemandMoleculesList,
      }}
    >
      {children}
    </onDemandMoleculesContext.Provider>
  );
};
