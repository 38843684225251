import React, { useEffect, useState, useContext} from 'react';
import { API, Auth } from "aws-amplify";
import { Card } from 'antd';
import { Tag, Col, Row } from 'antd';
import "./CalculationResult.css";
import { useParams } from "react-router-dom";
import { SmiDrawer } from "smiles-drawer";
import {Typography} from 'antd';
import { Link} from "react-router-dom";
import { SideMenuContext } from '../context/sidemenu';
import { Table } from "ant-table-extensions";
import { CartContext } from '../context/cart';
import { ToastContainer,toast } from 'react-toastify';
import { Button} from 'antd';
const {Paragraph} = Typography;


export default function ComputationResultHistory(){
    const [batchData, setBatchData] = useState([]);
    const [createdAt, setCreatedAt] = useState();
    const [onDemandResList, setOnDemandResList] = useState([]);
    const {setSelectedMenuItem } = useContext(SideMenuContext);
    const { addToCartMultiple } = useContext(CartContext);
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    const [dataResult, setDataResult] = useState({});
    const [propertyName, setPropertyName] = useState();
    const [calculatorId, setCalculatorId] = useState();
    const [dataLoaded, setDataLoaded] = useState(false)

    const columns = [
        {
          title: 'InChIkey',
          dataIndex: 'molId',
          render: (molId) => <Link to={`/moleculeView/${molId}`} >{molId}</Link>
        },
        {
        title: 'Smiles',
        dataIndex: 'smiles',
        render: (smiles,row) => <Link to={`/moleculeView/${row.molId}`} >{smiles}</Link>
        },
        {
        title: 'Value',
        dataIndex: 'value',
        },
        {
        title: 'Files',
        dataIndex: 'files',
        render: (html) => {return <div dangerouslySetInnerHTML={{__html: html}} />}
        },
        ];
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
      };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const {batchId}=useParams();
    async function handlePastResults () {
        setLoading(true);
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        const request = {
            headers: {
                Authorization: "Bearer " + token
            },
            body : batchId,
        };
        const jsonDataResult= await API.put("diadem", "/calculation/computation-history", request);
        if (jsonDataResult.statusCode===200){
            setLoading(false);
            setDataResult(jsonDataResult["body"]);
            setDataLoaded(true);
            setPropertyName(jsonDataResult["body"][0]["propertyTypeName"])
            setCalculatorId(jsonDataResult["body"][0]["calculatorId"])
          }
        else{
            const body=JSON.parse(jsonDataResult.body)
            if (body && body.exception){
                toast.error(body.exception);
                setLoading(false);
            }
            }
    }

    async function batchDataCalc() {
        let onDemandResult=[];
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;

        const request = {
            headers: {
                Authorization: "Bearer " + token
            },
            body:{
                batchId: batchId
            }
        };

        const jsonBatchData= await API.put("diadem", "/calculation/mainLoopBatchHistory", request);

        const onDemandData=jsonBatchData["body"]
        const createdAtOnDemand=onDemandData[0]["createdAt"]
        setCreatedAt(createdAtOnDemand)

        onDemandData.map((itemOnDemand)=>{
            const molIdOnDemand=itemOnDemand["molId"]
            dataResult.map((itemResult)=>{
                if (itemResult["molId"]===molIdOnDemand){
                    let item={  "molId": molIdOnDemand,
                            "smiles": itemOnDemand["smiles"],
                            "runStatus": itemOnDemand["runStatus"],
                            "propertyTypeId": itemOnDemand["propertyTypeId"],
                            "calculatorref": itemOnDemand["calculatorref"],
                            "unit": itemOnDemand["unit"]
                        }
                    onDemandResult.push(item)
                }
                return null;
            })
            return null;
        });
        setBatchData(onDemandResult);


        let odlist = []
        for (const item of onDemandResult) {
            const data=await dataMoleculePC(item.molId, item.runStatus, item.propertyTypeId, item.calculatorref);
            const preUrlDict=await preSignedUrl(item.calculatorref, item.molId, item.runStatus);
            const notEmptyData=(Object.keys(data).length !== 0)
            // const notEmptyUrl=(Object.keys(preUrlDict).length !== 0)
            if (notEmptyData && (preUrlDict!== null)){
                odlist.push([data,preUrlDict]);
            }

        }
        setOnDemandResList(odlist);
        }
        const csvData = selectedRowKeys.map(i => ({
                    inchiKey: onDemandResList[i][0].molId,
                    smiles: onDemandResList[i][0].smiles,
                    value: onDemandResList[i][0].value
                }));
        const data = [];
        const notEmpty=(Object.keys(onDemandResList).length !== 0)
        const resultLength=onDemandResList.length
        if (notEmpty){
            for (let i = 0; i < resultLength; i++) {

                let filedl = '';

                Object.entries(onDemandResList[i][1]).map(([key, value]) => (

                    filedl += `<li key="${key}">
                        <a href="${value}" target="_blank" rel="noopener noreferrer">
                            ${key.charAt(0).toUpperCase() + key.slice(1)}
                        </a>
                    </li>`
                ))
                data.push({
                    key:i,
                    molId: onDemandResList[i][0].molId,
                    smiles: onDemandResList[i][0].smiles,
                    value: `${onDemandResList[i][0].value} ${onDemandResList[i][0].unit}`,
                    files: filedl,
                });
                }
        }
    // eslint-disable-next-line
    useEffect(() => {
        handlePastResults(); // eslint-disable-line
        setSelectedMenuItem("dashboard"); // eslint-disable-line
      }, []); // eslint-disable-line

    useEffect(() => {
        if(dataLoaded){
            batchDataCalc(); // eslint-disable-line
        }
    }, [dataLoaded]); // eslint-disable-line

    useEffect(() => {
        SmiDrawer.apply();
    }, [batchData]); // eslint-disable-line

    async function dataMoleculePC(molId, runStatus, propertyTypeId,onDemanCalculatorref) {
        let valueListOnDemand=[]
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        const molBody={"molId":molId}
        const request = {
            headers: {
                Authorization: "Bearer " + token
            },
            body : molBody,
        };
        const jsonDataPC= await API.put("diadem", "/molecule/properties", request);
        const dataPc=jsonDataPC["body"]
        const moleculeDict=dataPc["molecule"]
        const smiles= moleculeDict["smiles"]
        const moleculePropertiesList=dataPc["moleculeProperties"]

        moleculePropertiesList.map((item, index)=>{
            if (runStatus==="finished" && item["propertyTypeId"]===propertyTypeId && onDemanCalculatorref=== item["calculatorref"] ){
                const value=item["value"]
                valueListOnDemand.push({
                    value: value,
                    molId: molId,
                    smiles: smiles,
                    unit: item["unit"]
                })
            }
            return null;
        })
        if (valueListOnDemand.length > 0){
            return(valueListOnDemand[0])
        }
        return(valueListOnDemand);
    }

    async function preSignedUrl(calculatorref, molId, runStatus) {
        if (runStatus==="finished"){
            const user = await Auth.currentAuthenticatedUser();
            const token = user.signInUserSession.idToken.jwtToken;
            const presignedUrlBody={
                "calculatorref": calculatorref,
                "molId": molId
            }
            const request = {
                headers: {
                    Authorization: "Bearer " + token
                },
                body : presignedUrlBody,
            };
            const preData= await API.put("diadem", "/calculation/presignedUrl", request);
            const preUrl=preData["body"]
            return(preUrl);
            }
    }

    function getBackgroundColor(mobj) {
        let runStatus = mobj.item.runStatus;
        if (runStatus === 'running') {
            return 'warning';
        }
        if (runStatus === 'failure') {
            return 'error';
        }
        return 'success';
    };
    const addMoleculesToCart = (event) => {
        addToCartMultiple(csvData);
        toast.success("Molecules added to cart successfully!");
      };
    return (
        <div className="calculation-result">
            <ToastContainer position="top-center" />
            <h1>CalculationResult</h1>

            <div className="container-up">

                    <Card className="calculation-list" title="Calculation List" bordered={false}>
                        <Row className="cal-result-header">
                            <Col span={6}><b>Molecule</b></Col>
                            <Col span={10}><b>InChIKey and Smiles</b></Col>
                            <Col span={4}><b>Run Status</b></Col>
                        </Row>
                        <ol type="1">
                            {
                                batchData.map((item, index)=>(
                                    <li>
                                        <Row>
                                            <Col span={6}><svg data-smiles={item.smiles} /></Col>
                                            <Col span={10} className="id-and-smiles"><Link to={`/moleculeView/${item.molId}`} >{item.molId}</Link><br/><Paragraph ellipsis={{ rows: 1, expandable: true }}>{item.smiles}</Paragraph></Col>
                                            <Col span={4} className="cal-result-status"><Tag color={getBackgroundColor({item})}>{item.runStatus}</Tag></Col>
                                        </Row>
                                    </li>
                                ))
                            }
                        </ol>

                    </Card>
                    <Card className="calculation-details" title="Calculation Details">
                            <div><b>Property : </b>{propertyName}</div>
                            <div><b>Computed with Calculator : </b>{calculatorId}</div>
                            <div><b>Runtime : </b>X days, X hours</div>
                            <div><b>Batch Id : </b>{batchId}</div>
                            <div><b>Submitted At : </b>{createdAt}</div>

                    </Card>

            </div>
            <Button type="primary" loading={loading} onClick={addMoleculesToCart}>Add to cart</Button>
            <Table rowSelection={rowSelection} columns={columns} dataSource={data} scroll={{ x: 400 }} searchable/>
            {SmiDrawer.apply()}
        </div>
      );
}
