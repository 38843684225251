import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import React, { useContext} from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo1.png";
import "./Header.css";
import { AppContext } from "../lib/contextLib";
import { CartContext } from '../context/cart';
import { onDemandMoleculesContext } from '../context/onDemandMolecules';
import {
  ShoppingCartOutlined,
} from '@ant-design/icons';

export default function Header(){
    const { isAuthenticated, userHasAuthenticated } = useContext(AppContext);
    const { cartItems, clearCart } = useContext(CartContext);
    const { clearOnDemandMoleculesList } = useContext(onDemandMoleculesContext)
    const nav = useNavigate();
    async function handleLogout() {
        await Auth.signOut();
        clearCart();
        clearOnDemandMoleculesList();
        userHasAuthenticated(false);
        nav("/login");
      }
    const cartClick = () => {
      nav("/cart")
    }
    return(
        <Navbar collapseOnSelect expand="md" className="mb-3 px-3 main-navbar">
          <LinkContainer to="/">
            <img src={logo} alt="logo" className="navbar-logo"></img>
          </LinkContainer>
          <LinkContainer to="/">
            <Navbar.Brand className="brand-name">DiaDEM</Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav activeKey={window.location.pathname}>
              {isAuthenticated ? (
                <>
                <Nav.Link onClick={handleLogout} className="logout-link">Logout</Nav.Link>
                <span className="cart-menu" >
                  <ShoppingCartOutlined />
                      <Nav.Link onClick={cartClick}>
                        Cart ({cartItems.length})
                      </Nav.Link>
                </span>
                </>

              ) : (
                <>
                  <LinkContainer to="/signup">
                    <Nav.Link>Signup</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/login">
                    <Nav.Link>Login</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/about">
                    <Nav.Link>About</Nav.Link>
                  </LinkContainer>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>

    );
}
