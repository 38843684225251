import React from "react";
import "./Home.css";
import comp_img from "../assets/comp_homepage.svg";
import molecule_img from "../assets/molecule_homepage.svg";
import database_img from "../assets/database_homepage.svg";
import { Col, Row } from 'antd';

export default function Home() {
  return (
    <div className="Home">
      <div className="lander">
        <h1>DiaDEM</h1>
        <p className="text-muted text-sub">Digital Discovery platform for Organic Electronic Materials</p>
      </div>
      <Row className="homepag-svg-container">
        <Col span={7}>
          <div><img src={molecule_img} alt="Molecule SVG" className="homepage-svg"></img></div>
          <div className="svg-txt">12,345,674<br/> molecules</div>
        </Col>
        <Col span={7}>
          <div><img src={database_img} alt="Database SVG" className="homepage-svg"></img></div>
          <div className="svg-txt">2,255,700<br/> properties</div>
        </Col>
        <Col span={7}>
          <div><img src={comp_img} alt="Comp SVG" className="homepage-svg"></img></div>
          <div className="svg-txt">1,890,120<br/> calculations</div>
        </Col>

      </Row>
    </div>
  );
}
