import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import QueryResultChild from "./QueryResultChild";
import QueryResultBarChild from "./QueryResultBarChild";
import QueryResultRefineChild from "./QueryResultRefineChild";
import "./QueryResult.css"
import { SideMenuContext } from '../context/sidemenu';

function QueryResult(){
    const location = useLocation();
    const {setSelectedMenuItem } = useContext(SideMenuContext)
    const data = location.state?.data || '';
    // Extract specific properties from the 'data' object
    // eslint-disable-next-line
    const { statusCode, headers, body } = data;
    const [resultBody,]=useState(JSON.stringify(body))

    // eslint-disable-next-line
    useEffect(() => { setSelectedMenuItem("search") }, []);

    if(statusCode===200){
        return (
            <div>
                <h1>Results</h1>
                <div className="result-banner-container">
                    <QueryResultRefineChild resultBody={resultBody}></QueryResultRefineChild>
                    <QueryResultBarChild resultBody={resultBody}></QueryResultBarChild>
                </div>
                <QueryResultChild resultBody={resultBody}></QueryResultChild>
            </div>
        );
    }
    if(statusCode===500){
        return (
            <>
                <div>
                    <h1>Results not found, Please refine query</h1>
                </div>
            </>

        );
    }

}

export default QueryResult;
