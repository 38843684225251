import React, { useState, useEffect, useContext} from "react";
import { useParams} from "react-router-dom";
import { SmiDrawer } from "smiles-drawer";
import "./MoleculeView.css";
import {Button, Card } from 'antd';
import { API, Auth } from "aws-amplify";
import { Table } from "ant-table-extensions";
import { CartContext } from '../context/cart';
import { ToastContainer, toast } from 'react-toastify';
import { SideMenuContext } from '../context/sidemenu';
import { CSVLink } from "react-csv";

export default function MoleculeView(){
    const [dataMolecule, setDataMolecule] = useState({});
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false);
    const { addToCart } = useContext(CartContext);
    const { setSelectedMenuItem } = useContext(SideMenuContext)
    const {molId}=useParams();
    const columns = [
        {
          title: 'Property',
          dataIndex: 'propertyName',
        },
        {
          title: 'Calculator',
          dataIndex: 'calculatorName',
        },
        {
          title: 'Calculator Version',
          dataIndex: 'version',
        },
        {
          title: 'Value',
          dataIndex: 'value',
        },
      ];

    // let moleculeOptions = {};
    // let reactionOptions = {};
    // let sd = new SmiDrawer(moleculeOptions, reactionOptions);
    async function dataMoleculePC() {
        setIsLoading(true);
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        const molBody={"molId":molId}
        const request = {
            headers: {
                Authorization: "Bearer " + token
            },
            body : molBody,
        };
        const jsonDataPC= await API.put("diadem", "/molecule/properties", request);
        setDataMolecule(jsonDataPC["body"]);
        setIsLoading(false);

    }
   // eslint-disable-next-line
    useEffect(() => {
        dataMoleculePC(); // eslint-disable-line
        setSelectedMenuItem("search"); // eslint-disable-line
    }, []); // eslint-disable-line

    useEffect(() => {
        SmiDrawer.apply(); // eslint-disable-line
    }, [dataMolecule]); // eslint-disable-line

    const notEmpty=(Object.keys(dataMolecule).length !== 0)
    const data = [];
    const csvData=[];
    if(Object.keys(dataMolecule).length !== 0){
        const moleculePropertiesList=dataMolecule["moleculeProperties"];
        const resultLength=moleculePropertiesList.length;
        for (let i = 0; i < resultLength; i++) {
            const valueType=dataMolecule["moleculeProperties"][i]["valueType"]
            let valueProperty;
            if (valueType === "num") {
                if ('value' in moleculePropertiesList[i]) {
                    valueProperty = Number(moleculePropertiesList[i]["value"]).toFixed(2);
                }
            } else if (valueType === "bool") {
                if ('valueBool' in moleculePropertiesList[i]) {
                    valueProperty = moleculePropertiesList[i]["valueBool"] ? 'True' : 'False';
                }
            } else if (valueType === "string") {
                if ('valueString' in moleculePropertiesList[i]) {
                    valueProperty = moleculePropertiesList[i]["valueString"];
                }
            }
            data.push({
                key:i,
                propertyName: moleculePropertiesList[i]["propertyTypeName"],
                calculatorName: moleculePropertiesList[i]["calculatorId"],
                version: moleculePropertiesList[i]["version"],
                value: `${valueProperty} ${moleculePropertiesList[i]["unit"]}`,
            });
        }
    }

    const addMolToCart = (event) => {
        addToCart(dataMolecule.molecule);
        toast.success("Molecule added to cart successfully!");
    };
    const resultLength=data.length;
    const moleculePropertiesList=dataMolecule["moleculeProperties"];
    if (notEmpty){
        for (let i = 0; i < resultLength; i++) {
            csvData.push({
                key:i+1,
                propertyName: data[i]["propertyName"],
                calculatorName: data[i]["calculatorName"],
                version: data[i]["version"],
                value: data[i]["value"],
                unit: moleculePropertiesList[i]["unit"]
            });
        }
      }

    return(
        <>
            <ToastContainer position="top-center" />

            {notEmpty ? (
                <div>
                    <h4>SMILES: {dataMolecule.molecule.smiles}</h4>
                    <h6>INCHIKEY: {dataMolecule.molecule.inchiKey}</h6>
                    <div className="molecule-banner-container">
                        <div className="molsvg-container">
                            {/* <svg id="molSvg" /> */}
                            <svg data-smiles={dataMolecule["molecule"]["smiles"]} />
                        </div>
                        <Card title="Molecule Details" bordered={true} style={{ height: 300 }}>
                            <p>SMILES: {dataMolecule.molecule.smiles}</p>
                            <p>INCHIKEY: {dataMolecule.molecule.inchiKey}</p>
                            <p>Price: 120 Euros/10mg</p>
                            <p>Availability: In stock</p>
                        </Card>
                    </div>
                    {/* {sd.draw(dataMolecule["molecule"]["smiles"], '#molSvg')} */}
                    <div className="mol-detail-btn-container">
                        <Button type="primary">Find similar molecules</Button>
                        <Button type="primary">Run on-demand calculation</Button>
                        <Button type="primary" onClick={addMolToCart}>Add to cart</Button>
                        <Button type="primary" >
                            <CSVLink className="downloadbtn" filename="my-file.csv" data={csvData}>
                                Export to CSV
                            </CSVLink>
                        </Button>
                    </div>
                    <div>
                        <Table columns={columns} dataSource={data} />
                    </div>

                </div> ) :
            (
                <div class="loading" id="loading-wrapper">
                    <div id="loading-text">LOADING</div>
                    <div id="loading-content"></div>
                </div>
            )}


        </>
    );
}
