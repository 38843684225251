import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import 'mdb-react-ui-kit/dist/css/mdb.min.css';
// import './Mdb.css';
import './Mdb_minified.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
// import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { SideMenuProvider } from './context/sidemenu'

Amplify.configure({
  // For staging
  Auth: {
    mandatorySignIn: true,
    region: "us-east-1",
    userPoolId: "us-east-1_bKetRE9Jb",
    userPoolWebClientId: "mgl1vj3sdchj7sbsgne15b9ab",
  },
  API: {
    endpoints: [
      {
        name: "diadem",
        endpoint: "https://api.diadem-staging.de",
        region: "us-east-1",
      },
    ],
  },
  // For local testing
  // Auth: {
  //   mandatorySignIn: true,
  //   region: "us-east-1",
  //   userPoolId: "us-east-1_pYDMhIHj8",
  //   userPoolWebClientId: "15saagh88uo019nmo8qv3obva5",
  // },
  // API: {
  //   endpoints: [
  //     {
  //       name: "diadem",
  //       endpoint: "https://s25m13pbfg.execute-api.us-east-1.amazonaws.com",
  //       region: "us-east-1",
  //     },
  //   ],
  // },
  authenticationFlowType: 'USER_PASSWORD_AUTH'
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Router>
        <SideMenuProvider>
          <App />
        </SideMenuProvider>
      </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
